<template>
  <section class="booking-current-date">

    <span style="font-size: 24px;font-weight: bold;">
      <span class="header-date">{{ this.$store.getters.getDateHeader | moment("dddd | D MMMM  YYYY р") | capitalize }}</span>
      <b-badge
        v-if="dateToday === $store.getters.getDateHeader"
        variant="light"
        class="custom"
      >Сьогодні</b-badge><br>
    </span>
  </section>
</template>

<script>
export default {
  name: "BookingCurrentDate",
  data: () => ({

  }),
  computed: {
    dateToday: function () {
      return this.$moment(this.$store.state.dateToday).format('YYYY-MM-DD');
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style lang="scss">
.badge.custom {
  font-size: 12px;
  background: #f4f8f9;
  color: #239cb9;
  position: relative;
  top: -4px;
  left: 6px;
}
</style>
