import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import CancelBooking from '../views/CancelBooking.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: () => import('../views/Rooms.vue')
  },
  {
    path: '/room/:id',
    name: 'room',
    component: () => import('../views/Room.vue')
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('../views/Booking.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue'),
 
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/cancel-booking',
    name: 'CancelBooking',
    component: () => import('../views/CancelBooking.vue')
  },
  {
    path: '/admin/bookings',
    name: 'admin-bookings',
    component: () => import('../views/AdminBookings.vue'),
    meta: { 
      requiresAuth: true,
      is_admin : true
    }
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('../views/AdminUsers.vue'),
    meta: { 
      requiresAuth: true,
      is_admin : true
    }
  },
  {
    path: '/admin/profile',
    name: 'admin-profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/admin/users/:id',
    name: 'user',
    component: () => import('../views/UserItem.vue'),
    meta: { 
      requiresAuth: true,
      is_admin : true
    }
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import('../views/TermsConditions.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue')
  }
  
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // beforeEach(to, from, next) {
  //   console.log(1);
    
  // },
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },

  routes
})


// router.beforeEach((to, from, next) => {
//   if (localStorage.getItem('token')) {
//     next('/admin/users')
//   } 
//   else next()
// })





export default router

