<template>
  <section class="navigation-top">
<!-- toggleable="lg" -->
    <b-navbar >
      <b-navbar-brand :to="{ name: 'booking'}">
        <img
            alt=""
            src="/img/logos/roomlogo.svg"
            style="height: 40px;"
          >
        <span class="logo__label" :style="'font-size:'+logoLabelSize">{{logoLabel}}</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="!$store.getters.getIsAdmin">
          <b-nav-item :to="{ name: 'booking'}">Забронювати кімнату</b-nav-item>
          <b-nav-item
            v-if="$store.getters.getToken"
            :to="{ name: 'report'}"
          >Мої бронювання</b-nav-item>
          <b-nav-item
            v-if="$store.getters.getToken"
            :to="{ name: 'profile'}"
          >Мій профіль</b-nav-item>
          <b-nav-item :to="{ name: 'about'}">Про сервіс</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-else>
          <b-nav-item :to="{ name: 'admin-bookings'}">Бронювання</b-nav-item>
          <b-nav-item :to="{ name: 'admin-users'}">Користувачі</b-nav-item>
          <b-nav-item :to="{ name: 'booking'}">Забронювати кімнату</b-nav-item>
          <b-nav-item :to="{ name: 'admin-profile'}">Мій профіль</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <template v-if="this.$store.getters.getToken">
            <b-button
              id="SignOutButton"
              variant="outline-primary"
              style="display: flex; justify-content: center; align-items: center"
              @click="signOut"
            >
              <v-icon
                name="user"
                style="height: 16px; width: 16px"
              ></v-icon>
              Вийти
            </b-button>
            <b-tooltip
              target="SignOutButton"
              noninteractive
            >Вийти з системи</b-tooltip>
          </template>

          <div v-else>
            <b-button
              id="SignInButton"
              variant="outline-primary"
              style="display: flex; justify-content: center; align-items: center"
              @click="login"
            >
              <v-icon
                name="user"
                style="height: 16px; width: 16px"
              ></v-icon>
              Увійти
            </b-button>
            <b-tooltip
              target="SignInButton"
              noninteractive
            >Увійдіть, щоб забронювати кімнату</b-tooltip>
          </div>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <Login />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Login from "./Login.vue";
import  jwtDecode from 'jwt-decode';
export default {
  name: "NavigationTop",
  components: {
    Login
  },
  data: () => ({
    logoLabel: process.env.VUE_APP_LOGO_LABEL,
    logoLabelSize: process.env.VUE_APP_LOGO_LABEL_SIZE
  }),
  computed: mapState({
    rooms: ({ rooms }) => rooms.rooms,
  }),
  methods: {
    login () {
      this.$bvModal.show('modal-login-step-1');
      this.$store.commit('setReserveAfterLogin', false)
    },
    signOut () {
      if((this.$route.name === 'admin-profile' || this.$route.name === 'profile') && !this.$store.getters.getChangesSaved) {
        this.$store.commit('setClickedSignOut', true);
      } else {
        localStorage.removeItem('token');
        this.$store.commit('setToken', null);
      }

      if (this.$route.name !== 'booking') {
        this.$router.push({name: 'booking'});
      }

      sessionStorage.removeItem('admin-bookings-date')
      sessionStorage.removeItem('admin-bookings-room-type')
    }
  },
  mounted () {
    let token = localStorage.getItem('token');
    if (token) {
      this.$store.commit('setToken', token);
      let adm = jwtDecode(token)
      if(adm.is_admin) {
        this.$store.commit('setIsAdmin', adm.is_admin);
      }else{
        this.$store.commit('setIsAdmin', false);
      }
    }
  },
  updated() {
    let token = localStorage.getItem('token');
      if (token) {
        this.$store.commit('setToken', token);
        let adm = jwtDecode(token)
        if(adm.is_admin) {
          this.$store.commit('setIsAdmin', adm.is_admin);
        }else{
          this.$store.commit('setIsAdmin', false);
        }
      }
  },
};
</script>
<style lang="scss">
.navbar.navbar-light.navbar-expand-lg {
  padding: 0.5rem 0;
}
.navbar-brand{
  position: relative;
}
.logo__label{
  position: absolute;
  top: 32px;
  left: 49px;
  font-size: 12px;
  color: #28aace;
}
.navigation-top {
  border-bottom: 1px solid #eee;
  .nav-link {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -14px;
      width: 100%;
      height: 2px;
      background-color: #239cb9;
      opacity: 0;
      transition: 0.4s ease;
    }
    &.router-link-exact-active:after {
      opacity: 1;
    }
  }
}
</style>
