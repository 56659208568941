import axios from 'axios'
import moment from 'moment-timezone'

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

import {
  cloneDeep,
  groupBy
} from 'lodash'

export default {
  state: {
    curentSoltIdVal: null,
    period: Number(sessionStorage.getItem('storePeriod')),
    updateRenderSlots: false,
    upDate: moment().format('YYYY-MM-DD'),
    bottomDate: moment().format('YYYY-MM-DD'),
    isLoadSlots: true,
    scrollArrSlotsByDayPart: {},
    typeScroll: null,
    allowScroll: false,
    slotsApi: null,
    slotsBack: [],
    slotsFront: [],
    sortedSlots: [], // Создаем массив с объектами комнат
    sortedSlotsByRoom: [], // Создаем массив с определенными комнатами
    sortedSlotsGroup: [], // Группируем массив по периодам "08:00 - 09:00"
    sortedSlotsByDayPart: [{
      morning: []
    }, {
      day: []
    }, {
      evening: []
    }], // Группируем массив по частям дня
    sortedSlotsByDayPartByRoom: [], // Группируем массив по частям дня с определенными комнатами
  },
  getters: {
    getCurentSoltIdVal: state=>state.curentSoltIdVal,
    getAllowScroll: state=>state.allowScroll,
    getTypeScroll: state=>state.typeScroll,
    getUpdateRenderSlots: state=>state.updateRenderSlots,
    getUpDate: state => state.upDate,
    getBottomDate: state => state.bottomDate,
    getIsLoadSlots: state => state.isLoadSlots,
    getScrollArrSlotsByDayPart: state => state.scrollArrSlotsByDayPart,
    getSlotsBack: state => state.slotsBack,

    getPeriod: state => state.period,
    getFrontSlots: state => state.slotsFront,
    getsortedSlots: state => state.sortedSlots,
    getsortedSlotsByRoom: state => state.sortedSlotsByRoom,
    getsortedSlotsGroup: state => state.sortedSlotsGroup,
    getsortedSlotsByDayPart: state => state.sortedSlotsByDayPart,
    getsortedSlotsByDayPartByRoom: state => state.sortedSlotsByDayPartByRoom,
  },
  mutations: {
    setCurentSoltIdVal(state, payload){
      state.curentSoltIdVal = payload
    },
    setAllowScroll(state, payload){
      state.allowScroll = payload
    },
    setTypeScroll(state, payload){
      state.typeScroll = payload
    },
    setUpdateRenderSlots(state, payload){
      state.updateRenderSlots = payload
    },
    setUpDate(state, payload){
      state.upDate = payload
    },
    setBottomDate(state, payload){
      state.bottomDate = payload
    },
    setIsLoadSlots(state, payload){
      state.isLoadSlots = payload
    },
    resetScrollArrSlotsByDayPart(state){
      state.scrollArrSlotsByDayPart = {}
      state.upDate = moment().format('YYYY-MM-DD')
      state.bottomDate = moment().format('YYYY-MM-DD')
    },
    setScrollArrSlotsByDayPart(state, payload){
      state.scrollArrSlotsByDayPart[moment(payload.date).format('YYYYMMDD')] = payload.arr;
    },


    SET_PERIOD: (state, n) => {
      state.period = n
    },
    SET_NEW_SLOTS: (state, payload) => {
      state.slotsApi = payload;
      state.slotsBack.push(state.slotsApi);
    },
    SLOTS_MUTATION: (state, dateSlot) => {
      state.slotsFront = [];
      let period;
      if(Number(state.period) === 0){
        period = 1;
      } else{
        period = Number(state.period);
      }
      
      
      
      // let half = 0.5;
      let half = Number(process.env.VUE_APP_HALF_PERIOD)
      
      let date = dateSlot;
      //console.log(period);

      // console.log(state.slotsBack);
      

      // Находим объект в массиве с нужной датой let date
      const newObjectWithDate = state.slotsBack.find(item => item.date === date);
      // Создаем копию найденного объекта
      const newObjecWithDateGenerated = _.cloneDeep(newObjectWithDate)
      // Очищаем массивы слотов в комнатах
      
      // console.log(newObjectWithDate);
      
          
      for (let roomId in newObjectWithDate.availability) {
        newObjecWithDateGenerated.availability[roomId] = []
      }
      // Проходим по комнатам и считаем слоты
      {
        const momentFormat = 'YYYY-MM-DD HH:mm:SS';
        const mf = momentFormat;
        const dateSelectForMoment = newObjectWithDate.availability;
        const d = dateSelectForMoment;

        for (let roomId in newObjectWithDate.availability) {
          for (let i = 0; i < newObjectWithDate.availability[roomId].length; i++) {
            // console.log(d[roomId][i].start)
            // console.log(d[roomId][i].end)
            // Длина слота от конца до начала в часах difference
            let diff = moment(d[roomId][i].end).diff(d[roomId][i].start, 'hours', true);
            let newVal = null;
            newVal = newObjecWithDateGenerated.availability[roomId];
            // console.error(diff)

            // Если слот больше 1 часа
            if (diff > 1) {
              // Генерируем слоты в рамках разницы периода
              for (let step = 0; step <= diff - period; step++) {
                newVal.push({
                  start: moment(d[roomId][i].start).add(+step, 'hours').format(mf),
                  end: moment(d[roomId][i].start).add(step + period, "hours").format(mf)
                })
                // console.warn("start " + moment(d[roomId][i].start).add(+step, 'hours').format(mf));
                // console.warn("end " + moment(d[roomId][i].start).add(step + period, "hours").format(mf));
              }
              // Генерируем слоты в рамках разницы периода + полчаса
              if(half === 0.5) {
                for (let step = 0; step <= diff - period - half; step++) {
                  newVal.push({
                    start: moment(d[roomId][i].start).add(+step + half, "hours").format(mf),
                    end: moment(d[roomId][i].start).add(step + period + half, "hours").format(mf)
                  })
                }
              }
             
            }

            // Если слот меньше 1 часа
            else if (diff <= 1) {
              for (let step = 0; step <= diff - period; step++) {
                newVal.push({
                  start: moment(d[roomId][i].start).add(+step, 'hours'),
                  end: moment(d[roomId][i].start).add(step + period, "hours")
                })
              }
            }

          }
        }
      }

      // console.log(newObjectWithDate.availability)
      // console.warn(newObjecWithDateGenerated)
      state.slotsFront = [];
      state.slotsFront.push(newObjecWithDateGenerated);
      state.sortedSlots = [];
      { //Получаем входящий объект дня (поменять 0 на итерацию от скрола)

        let obj = state.slotsFront[0].availability;
        let count = 0;
        for (let key in obj) {
          obj[key].forEach(item => {
            // Пушим новый объект для UI
            // console.log(item.start);
            
            
            if(moment(item.start).isAfter(new Date())) {
              
              // console.log(moment(item.start));
              // let idValue = `${key}${count}${item.start}${item.end}`.replace(/[^+\d]/g, '')
              let idValue = `${key}${item.start}`.replace(/[^+\d]/g, '')


              state.sortedSlots.push({
                // id: count++,
                id: idValue,
                // sort - это для сортировки по числу от большего к меньшему. Тут текушее время приведенное к цифре
                sort: `${moment(item.start).diff(
                    moment(item.start).format("YYYY-MM-DD"),
                    "hours",
                    true
                )}`,
                period: `${moment(item.start).format("HH:mm")} - ${moment(
                    item.end
                ).format("HH:mm")}`,
                roomId: key,
                startDate: `${item.start}`,
                endDate: `${item.end}`
              });
            }
            
            
          });
        }

        state.sortedSlots.sort((a, b) => {
          return a.sort - b.sort;
        });
        state.sortedSlotsByRoom = [];
        state.sortedSlotsByRoom = state.sortedSlots.filter(function (el) {
          return el.roomId == 6;
        });
        state.sortedSlotsGroup = [];
        state.sortedSlotsGroup = _.groupBy(state.sortedSlots, "period");
      }
      //Day parts
      state.sortedSlotsByDayPart = [{
        morning: []
      }, {
        day: []
      }, {
        evening: []
      }];
      {
        let objDay = state.sortedSlotsGroup;
        for (let key in objDay) {
          if (Number(key.slice(0, 2)) < 13) {
            let objPeriod = {};
            objPeriod[key] = objDay[key];
            state.sortedSlotsByDayPart[0]["morning"].push(objPeriod);
          }

          if (Number(key.slice(0, 2)) >= 13 && Number(key.slice(0, 2)) < 18) {
            let objPeriod = {};
            objPeriod[key] = objDay[key];
            state.sortedSlotsByDayPart[1]["day"].push(objPeriod);
          }

          if (Number(key.slice(0, 2)) >= 18) {
            let objPeriod = {};
            objPeriod[key] = objDay[key];
            state.sortedSlotsByDayPart[2]["evening"].push(objPeriod);
          }
        }
      }
    }
  },
  actions: {
   GET_SLOTS_API({commit,getters}, date) {
      axios({
        method: "GET",
        url: `rooms/availability?date=${date}`
      }).then(function (response) {
        
        commit('SET_NEW_SLOTS', response.data)
        // console.log(response);
        commit('setUpdateRenderSlots', true);
        // commit('setAllowScroll', true) 

        commit("SLOTS_MUTATION", getters.getCurrentDate);
        commit("setScrollArrSlotsByDayPart", { date: getters.getCurrentDate, arr: getters.getsortedSlotsByDayPart });

      });
    },
    START_SLOTS_MUTATION: (context) => {
      context.commit('SLOTS_MUTATION')
    },

    ADD_SLOTS_AFTER_SCROLL ({commit,getters}, date){
    
        if (getters.getIsLoadSlots) {
        axios({
          method: "GET",
          url: `rooms/availability?date=${date}`
        }).then(function (response) {

          let newArr = response.data
          commit('SET_NEW_SLOTS', newArr);
          commit("SLOTS_MUTATION", date);
          commit("setScrollArrSlotsByDayPart", { date: date, arr: getters.getsortedSlotsByDayPart });
          commit('setIsLoadSlots', true)
          commit('setUpdateRenderSlots', true);

        //  if(document.querySelector('.today__heading')){
        //   document.querySelector('.today__heading').classList.remove('slots__day--hide');
        //  }

        if(getters.getTypeScroll === 'up'){
          let scrollArea = document.querySelector('.scroll-area')
          scrollArea.scrollTop = 5;
        }

        

   
        

       
        
         

        //  setTimeout(()=>{
        //   let headingRows = document.querySelectorAll('.hiding-row')
        //   for (let headingRow of headingRows) {
              
        //     headingRow.style.display = 'none';
        
        //   }
        //  })

          // commit('setDateHeader', date)

          // console.log(getters.getScrollArrSlotsByDayPart);
          
        });
      }
    commit('setIsLoadSlots', false);
    }
  },
}