import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment-timezone'
import rooms from './modules/rooms'
import reports from './modules/reports'
import slots from './modules/slots'
import login from './modules/login'
import admin from './modules/admin'
import profile from './modules/profile'
import bookings from './modules/bookings'
import adminUsers from './modules/admin-users'
import user from './modules/user'
import adminBookings from './modules/admin-bookings'

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: '',
    isUserAuth: false,
    dateHeader: moment().format('YYYY-MM-DD'),
    dateToday: moment().format('YYYY-MM-DD'),
    dateSelected: moment().format('YYYY-MM-DD'),
    selectedDate: moment().format('YYYY-MM-DD'),
    selectedPeriod: Number(sessionStorage.getItem('storePeriod')),
    selectedRoom: Number(sessionStorage.getItem('selectedRoom')),
  },

  getters: {
    getCurrentDate: state => {
      return state.dateSelected
    },
    getDateHeader: state => {
      return state.dateHeader
    }
  },
  mutations: {
    setDateHeader(state, date) {
      state.dateHeader = date
    },
    updateDate (state, message) {
      state.dateSelected = message
    },
    SET_SELECTED_ROOM: (state, n) => {
      state.selectedRoom = n
    },
  },
  actions: {},
  modules: {
    rooms,
    reports,
    slots,
    login,
    admin,
    profile,
    bookings,
    adminUsers,
    user,
    adminBookings
  }
})
