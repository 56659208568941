import axios from "axios";

// import moment from 'moment';
//import router from '../../router';
//import { cloneDeep, groupBy } from 'lodash';

export default {
  state: {
    payObject: null,
  },
  getters: {
    getPayObject: (state) => state.payObject,
  },
  mutations: {
    setPayObject(state, payload) {
      state.payObject = payload;
    },
  },
  actions: {
    setBookingAmount(context, payload) {
      const payObject = context.state.payObject;
      const { discount, period } = payObject;
      let priceAmount = parseFloat(payload);

      priceAmount = priceAmount * parseFloat(period);
      priceAmount = priceAmount * (1 - discount / 100);
      payObject.amount = priceAmount.toFixed(2);

      context.commit("setPayObject", payObject);
    },

    BOOKINGS_PAY({ dispatch, commit }, data) {
      axios({
        method: "POST",
        url: "bookings",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
          Accept: "application/json",
        },
        data,
      })
        .then(function (response) {
          console.log(response);

          sessionStorage.removeItem("current-room-id");
          sessionStorage.removeItem("type-reserve");
          sessionStorage.removeItem("current-room-day");
          sessionStorage.removeItem("current-slot-id");
          commit("setCurrentRoom", null);
          commit("setPayObject", null);
          sessionStorage.removeItem("pay-object");

          if (data.pay_type === 1) {
            let { url, data, signature } = response.data.data;

            let f = document.createElement("form");
            f.setAttribute("method", "post");
            f.setAttribute("action", url);

            let i = document.createElement("input");
            i.setAttribute("type", "hidden");
            i.setAttribute("name", "data");
            i.setAttribute("value", data);
            let j = document.createElement("input");
            j.setAttribute("type", "hidden");
            j.setAttribute("name", "signature");
            j.setAttribute("value", signature);

            f.appendChild(i);
            f.appendChild(j);

            document.getElementsByTagName("body")[0].appendChild(f);

            f.submit();
          }

          if (data.pay_type === 2) {
            // router.push({ name: 'report' });
            window.location.href = "/report";
          }
          commit("setReserveAfterLogin", false);
        })
        .catch(function (err) {
          console.log(err.message);
        });
    },
  },
};
