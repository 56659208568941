import axios from 'axios';
export default {
  state: {
    userData: null,
    isLoadUserData: false,
    changesSaved: true,
  },
  getters: {
    getChangesSaved: state => state.changesSaved,
    getUserData: state => state.userData,
    getIsLoadUserData: state => state.isLoadUserData,
  },
  mutations: {
    setChangesSaved(state, payload) {
      state.changesSaved = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setIsLoadUserData(state, payload) {
      state.isLoadUserData = payload;
    },
  },
  actions: {
    GET_USER_DATA({ commit }, user) {
      axios({
        method: "GET",
        url: `/users/${user}`,
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }
      }).then(function(response) {
  
        
        commit('setUserData', response.data)
        commit('setIsLoadUserData', true)
      });
    },
    SAVE_USER_DATA({ commit }, data) {
     
      axios({
        method: "PUT",
        url: `/users/${data.user}`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json'
        },
        data: data.obj
      }).then(function(response) {
        //console.log(response);
        
        // console.log(response.data);
      }).catch(function(err) {
     
        console.log(err);
        
        // console.log(response.data);
      });
    }
  }
};
