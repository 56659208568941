<template>
  <section class="booking-change-period">
    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text>
          <v-icon name="clock" style="height: 14px"></v-icon>
        </b-input-group-text>
      </template>
      <b-form-select @change="changePeriod" v-model="time.selected" :options="time.options"></b-form-select>
    </b-input-group>
  </section>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "BookingChangePeriod",
    computed: {
      ...mapGetters([
        "getPeriod"
      ])
    },
    data: () => ({
      time: {
        selected: String(Number(sessionStorage.getItem('storePeriod'))),
        options: [
          // {value: '1', text: '1 ч'},
          // {value: '1.5', text: '1 ч 30 мин'},
          // {value: '2', text: '2 ч'},
          // {value: '2.5', text: '2 ч 30 мин'},
          // {value: '3', text: '3 ч'},
          // {value: '3.5', text: '3 ч 30 мин'},
          // {value: '4', text: '4 ч'},
          // {value: '4.5', text: '4 ч 30 мин'},
          // {value: '5', text: '5 ч'},
          // {value: '5.5', text: '5 ч 30 мин'},
          // {value: '6', text: '6 ч'},
          // {value: '6.5', text: '6 ч 30 мин'},
          // {value: '7', text: '7 ч'},
          // {value: '7.5', text: '7 ч 30 мин'},
          // {value: '8', text: '8 ч'},
          // {value: '8.5', text: '8 ч 30 мин'},
          // {value: '9', text: '9 ч'},
          // {value: '9.5', text: '9 ч 30 мин'},
          // {value: '10', text: '10 ч'},
          // {value: '10.5', text: '10 ч 30 мин'},
          // {value: '11', text: '11 ч'},
          // {value: '11.5', text: '11 ч 30 мин'},
          // {value: '12', text: '12 ч'},
          // {value: '12.5', text: '12 ч 30 мин'},
          // {value: '13', text: '13 ч'},
          // {value: '13.5', text: '13 ч 30 мин'},
          // {value: '14', text: '14 ч'},
          // {value: '14.5', text: '14 ч 30 мин'},
          // {value: '15', text: '15 ч'}
        ]
      }
    }),
    created() {
    },
    mounted() {

      function isInteger(num) {
        return (num ^ 0) === num;
      }

      let min_h =  Number(process.env.VUE_APP_MIN_HOUR_PERIOD)
      let max_h =  Number(process.env.VUE_APP_MAX_HOUR_PERIOD)

      for(var i = min_h; i<=max_h; i+= process.env.VUE_APP_HALF_PERIOD == 0.5 ? 0.5 : 1){
        let newOtion = {}
        newOtion.value = i.toString()
        if(isInteger(i)){
          newOtion.text = i.toString() + ' ' +'г'
        }else{
          newOtion.text = (i-0.5).toString() + ' ' +'г' + ' 30 хв'
        }
        this.time.options.push(newOtion)
      }


      if(!sessionStorage.getItem('storePeriod')){
        this.time.selected = 1
        this.$store.commit('SET_PERIOD', 1)
      }
      
      // sessionStorage.setItem('storePeriod', 1);
    },
    methods: {
      changePeriod(e) {
        // console.log(this.$store.state.dateSelected);
        // console.log(this.$store.getters.getCurrentDate);

        this.$store.commit('SET_PERIOD', e);
        sessionStorage.setItem('storePeriod', e);
        // window.location.href = '/booking'
        // this.$router.push({path: 'booking'})

        sessionStorage.removeItem("current-room-id");
        sessionStorage.removeItem('type-reserve');
        sessionStorage.removeItem('event-type')
        sessionStorage.removeItem("current-room-day");
        sessionStorage.removeItem("current-slot-id");
        this.$store.commit('setCurrentRoom', null);

        this.$store.commit('setPayObject', null)
        sessionStorage.removeItem("pay-object");
        // this.$route.push({path: '/'})
        // window.location.href = '/booking'
        this.$store.commit('resetScrollArrSlotsByDayPart')
        this.$store.commit('setUpDate', this.$store.getters.getCurrentDate)
        this.$store.commit('setBottomDate', this.$store.getters.getCurrentDate)

        this.$store.commit("SLOTS_MUTATION", this.$store.getters.getCurrentDate);
        this.$store.commit("setScrollArrSlotsByDayPart", { date: this.$store.getters.getCurrentDate, arr: this.$store.getters.getsortedSlotsByDayPart });

        // setTimeout(()=>{
        //   {
        //     let allButNode = document.querySelectorAll('.booking-table-button')
        //     let allBut = Array.from(allButNode);
        //     for (let but of allBut) {
        //       but.classList.remove('active');
        //       but.classList.remove('inactive');
        //     }
        //   }
        // },200)
                //   {
        //     let allButNode = document.querySelectorAll('.booking-table-button')
        //     let allBut = Array.from(allButNode);
        //     for (let but of allBut) {
        //       but.classList.remove('active');
        //       but.classList.remove('inactive');
        //     }
        //   }
            this.$store.commit('setCurentSoltIdVal', null);
    

        // let top = document.querySelector('.slots__day[data-time="'+this.$store.getters.getCurrentDate+'"]').offsetTop
        let scrollArea = document.querySelector('.scroll-area')
        scrollArea.scrollTop = 5;
           this.$store.commit('setAllowScroll', false)


      
     
      }
    },
  }
</script>

<style lang="scss">
  .form-group {
    margin-bottom: 1rem;
    width: 90%;
    margin-left: 10%;
    padding-top: 1rem;
  }

  .custom-control {
    padding: .5rem;
    width: 90%;
    margin-bottom: .25rem;
    border: 1px #999;

    &-label {
      width: 100%;
    }
  }

  .custom-select {
    padding: 0 1.5rem 0 0;
    height: 32px;
    width: 100px;

  }
</style>
