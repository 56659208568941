<template>
  <section class="login">
    <div>
      <b-modal
          id="hello-modal"
          centered
          title=""
          hide-header
          hide-footer
          no-close-on-backdrop
        >
          <div class="mb-4">
            <img
              alt=""
              src="/img/logos/roomlogo.svg"
              style="height: 40px;"
            >
            <button
              @click="$bvModal.hide('hello-modal')"
              type="button"
              aria-label="Close"
              class="close"
            >×</button>
          </div>

          <h2 class="modal-heading">Вітаємо в системі Roomus!</h2>
          <div class="modal-description" v-html="startModalText">

          </div>

        </b-modal>




      <b-modal
        id="modal-login-step-1"
        centered
        title=""
        hide-header
        hide-footer
        no-close-on-backdrop
        @hidden="clearFields"
      >
        <div class="mb-4">
          <img
            alt=""
            src="/img/logos/roomlogo.svg"
            style="height: 40px;"
          >
          <button
            @click="$bvModal.hide('modal-login-step-1')"
            type="button"
            aria-label="Close"
            class="close"
          >×</button>
        </div>

        <h2 class="h2">Вхід</h2>
        <p class="my-2">Щоб отримати доступ до наших послуг, увійдіть, ввівши свій номер телефону. На нього ми надішлемо СМС з одноразовим кодом</p>
        <div class="my-4">
          <span>Номер телефону</span>
 
          <input
            id="PhoneInput"
            class="form-control"
            placeholder="XXX-XXX-XXXX"
            @input="inputPhone"
            maxlength="24"
            v-model="phone"
          >
          <div
            v-if="validation.phone == false"
            class="invalid-feedback d-block"
          >
            {{error.phone}}
          </div>
          <!-- <b-input
            :state="validation.phone"
            id="feedback-user"
            placeholder="XXX-XXX-XXXX"
            @input="inputPhone"
          ></b-input> -->
          <div class="my-3">
            <b-button
              id="StepLogin1"
              :disabled="!validation.phone"
              type="button"
              block
              variant="primary"
              @click="stepLogin2"
            >Продовжити</b-button>
            <b-tooltip
              target="StepLogin1"
              noninteractive
            >Натисніть, щоб отримати код на вказаний номер</b-tooltip>
          </div>
        </div>

        <p class="my-4">
          При вході в обліковий запис або при створенні нового облікового запису, ви погоджуєтесь з нашими <router-link :to="{name:'terms-conditions'}"><span @click="closeModals">Правилами та умовами</span></router-link>, а також <router-link :to="{name:'privacy-policy'}"><span @click="closeModals">Політикою конфіденційності</span></router-link>.
        </p>
      </b-modal>
      <b-modal
        id="modal-login-step-2"
        centered
        title=""
        hide-header
        hide-footer
        no-close-on-backdrop
        @hidden="clearFields"
      >
        <div class="mb-4">
          <img
            alt=""
            src="/img/logos/roomlogo.svg"
            style="height: 40px;"
          >
          <button
            @click="$bvModal.hide('modal-login-step-2')"
            type="button"
            aria-label="Close"
            class="close"
          >×</button>
        </div>

        <h2 class="h2">Вхід</h2>
        <p class="my-2">На номер {{phoneText}} було вислано одноразовий код. Якщо ви помилились при вводі номеру телефона,
          <a
            @click.prevent="stepLogin1"
            href="#"
          >ви можете ввести його заново.</a></p>
        <div class="my-4">
          <span>Одноразовий код</span>
          <input
            id="PasswordInput"
            class="form-control"
            placeholder="XXXXXX"
            @input="inputPassword"
            maxlength="6"
            v-model="password"
            autocomplete="one-time-code"
          >
          <!-- <b-input
            v-model="password"
            @input="inputPassword"
            type="text"
            placeholder="XXXXXX"
            :state="validation.password"
          ></b-input> -->
          <div
            v-if="$store.getters.getStatus === 'code-error'"
            class="invalid-feedback d-block"
          >
            Ви ввели неправильний одноразовий код.
          </div>
          <div class="my-3">
            <b-button
              id="StepLogin2"
              :disabled="!validation.password"
              type="button"
              block
              variant="primary"
              @click="Login"
            >Увійти</b-button>
            <b-tooltip
              target="StepLogin2"
              noninteractive
            >Натисніть, щоб увійти до системи</b-tooltip>

            <countdown
              v-if="counting"
              :time="60000"
              @end="handleCountdownEnd"
            >
              <template slot-scope="props">
                <div class="counting-message">Не отримали код? Вислати повторно можно через {{ props.totalSeconds }} секунд.</div>
              </template>
            </countdown>

            <div
              v-else
              class="counting-message"
            >
              <a
                @click.prevent="stepLogin2"
                href="#"
              >Надіслати код ще раз</a>
            </div>

          </div>
        </div>

        <p class="my-4">
          При вході в обліковий запис або при створенні нового облікового запису, ви погоджуєтесь з нашими Правилами та умовами, а також Політикою конфіденційності.
        </p>
      </b-modal>
    </div>
  </section>
</template>
<script>

import VueCountdown from '@chenfengyuan/vue-countdown';
import getContentFromFile from '@/utils/getContentFromFile.js'

export default {
  components: {
    countdown: VueCountdown
  },
  data: () => ({
    startModalText:null,
    counting: false,
    phone: null,
    phoneText: '',
    password: '',
    validation: {
      phone: null,
      password: null
    },
    error: {
      phone: ''
    }
  }),

  computed: {

  },

  methods: {
    startCountdown: function () {
      this.counting = true;
    },
    handleCountdownEnd: function () {
      this.counting = false;
    },

        // setInputFilter (textbox, inputFilter) {
        //   ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        //     if(textbox) {
        //       textbox.addEventListener(event, function () {
        //       if (inputFilter(this.value)) {
        //         this.oldValue = this.value;
        //         this.oldSelectionStart = this.selectionStart;
        //         this.oldSelectionEnd = this.selectionEnd;
        //       } else if (this.hasOwnProperty("oldValue")) {
        //         this.value = this.oldValue;
        //         this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        //       } else {
        //         this.value = "";
        //       }
        //     });
        //     }
            
        //   });
        // },
    inputPhone (e) {

  

        // this.setInputFilter(document.getElementById("PhoneInput"), function (value) {
        //   return /^[0-9-+( )]*$/.test(value);
        // });

      // let phoneValue = e.target.value.replace(/[^\d]/g, '');
      let phoneValue = e.target.value.replace(/[^-+() 0-9]/g, '');
      this.phone = phoneValue;
      this.phoneText = phoneValue
      // e.target.value = phoneValue


      let digits = parseInt(this.phone.replace(/\D+/g, "")).toString();

      let minDigits = digits.length >= 9;
      let maxDigits = digits.length <= 12;

      if (!maxDigits) {
        this.error.phone = 'Номер телефону має містити не більше 12 цифр';
      } else {
        this.error.phone = '';
      }

      let maxCharacters = this.phone.length <= 24;

      let pattern = /[0-9]/
      let testPattern = pattern.test(this.phone)

      if (minDigits && maxDigits && maxCharacters && testPattern) {
        this.validation.phone = true;
        e.target.classList.add('is-valid')
        e.target.classList.remove('is-invalid')
      } else {
        this.validation.phone = false;
        e.target.classList.remove('is-valid')
        e.target.classList.add('is-invalid')
      }

    },
    inputPassword (e) {
        // this.setInputFilter(document.getElementById("PasswordInput"), function (value) {
        //   return /^[0-9]*$/.test(value);
        // });


      let passwordValue = e.target.value.replace(/[^\d]/g, '');
      this.password = passwordValue;
      // e.target.value = passwordValue;

      let minMax = this.password.length === 6;
      let pattern = /[0-9]/
      let testPattern = pattern.test(this.password)

      if (minMax && testPattern) {
        this.validation.password = true;
        // e.target.classList.add('is-valid')
        // e.target.classList.remove('is-invalid')
      } else {
        this.validation.password = false;
        // e.target.classList.remove('is-valid')
        // e.target.classList.add('is-invalid')
      }
    },
    closeModals(){
      this.$bvModal.hide('modal-login-step-2');
      this.$bvModal.hide('modal-login-step-1');
    },
    stepLogin2 () {
      this.$store.dispatch('SEND_CODE', this)
    },
    stepLogin1 () {
      this.$bvModal.hide('modal-login-step-2');
      this.$bvModal.show('modal-login-step-1');
      this.$store.commit('setStatus', null);

      this.phone = null
      this.password = ''
      this.validation.phone = null
      this.validation.password = null
      
    },
    clearFields(){
      this.phone = null
      this.password = ''
      this.validation.phone = null
      this.validation.password = null
    },
    Login () {
      this.$store.dispatch('CHECK_CODE', this);
    }
  },
  async beforeMount() {
    const serviceName = process.env.VUE_APP_SERVICE_NAME;
    this.startModalText = await getContentFromFile(`content/${serviceName}/start-modal.txt`);
  },
  // mounted() {
  //   this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
  //     this.$store.commit('setReserveAfterLogin', true)
  //   })
  // }

};
</script>

<style>
.counting-message {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
