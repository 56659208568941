<template>
  <section class="booking">

    <Spiner v-if="!$store.getters.getAllRooms"></Spiner>
    
    <div class="booking-content" v-else  :style="[$store.getters.getRoomsIsLoad ? {'opacity': '1'} : {'opacity': '0'}]">
      <b-row>
        <b-col style="position:relative;">
          <section class="booking-filters">
            <BookingCurrentDate></BookingCurrentDate>
            <section class="select-params">
              <BookingCalendar></BookingCalendar>
              <BookingChangePeriod></BookingChangePeriod>
              <BookingRoomSelect></BookingRoomSelect>
            </section>
          </section>

          <BookingDaySlots></BookingDaySlots>
          <div id="booking-alert" style="display: none">
            Немає
          </div>
        </b-col>
        <b-col style="padding-top: 1rem;max-width: 320px">
          <Cart/>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
  import Cart from "@/views/components/Cart.vue";
  import BookingCalendar from "@/views/components/BookingCalendar.vue";
  import BookingRoomSelect from "@/views/components/BookingRoomSelect.vue";
  import BookingDaySlots from "@/views/components/BookingDaySlots.vue";
  import BookingCurrentDate from "@/views/components/BookingCurrentDate";
  import BookingChangePeriod from "@/views/components/BookingChangePeriod";
  import Spiner from "@/components/Spiner.vue";

  export default {
    name: 'home',
    components: {
      BookingChangePeriod,
      BookingCurrentDate,
      BookingDaySlots,
      BookingCalendar,
      Cart,
      BookingRoomSelect,
      Spiner
    },
    mounted(){
    }
    
  }
</script>
<style lang="scss">
  .booking{
    height: 100%;
  }

  .booking-filters {
    display: flex;
    align-items: center
  }

  .select-params {
    margin: .5rem 0;
    background: #F9F9F9;
    padding: 6px;
    border-radius: 4px;
  }

  .booking_info {
    display: flex;

    &-block {
      margin: 0 .25rem;
      padding: .25rem;
      background: #eee;
      flex: 1;

      &-name {
        font-size: 10px;
        color: #999;
      }

      &-value {
        font-size: 13px;
      }
    }
  }

  .select-params {
    width: 420px;
    max-width: 100%;
    margin-left: auto !important;
    display: flex;

    & > section:nth-child(2) {
      margin: 0 6px;
    }
  }

  .booking-table__inner:nth-child(1) .slots__day{
    display: none !important;
  }


</style>