<template>
  <section class="home">
    <Booking></Booking>
  </section>
</template>

<script>
  import Booking from "@/views/Booking.vue";

  export default {
    name: 'home',
    components: {
      Booking
    }
  }
</script>
<style lang="scss">

</style>