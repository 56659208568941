import axios from 'axios';
import  jwtDecode from 'jwt-decode';
import router from '../../router'
export default {
  state: {
    status: null
  },
  getters: {
    getStatusAdmin: state => state.status,
  },
  mutations: {
    setStatusAdmin(state, payload) {
      state.status = payload;
    }
  },
  actions: {
    ADMIN_LOGIN({ commit, getters }, data) {
      axios({
        method: 'POST',
        url: 'login/admin',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        data
      })
        .then(response => {
         //console.log(response);
          // console.log(jwtDecode(response.data.token));
          
          commit('setToken', response.data.token);
          localStorage.setItem('token', getters.getToken);
  
          router.push({name: 'admin-bookings'})

        })
        .catch(error => {
          console.log('Error SEND_CODE', error);
          commit('setStatusAdmin', 'admin-error');
        });
    },

    REDIRECT_TO_LOGIN_ADMIN({ commit, getters }){
      if (!getters.getIsAdmin) {
        router.push({ name: 'admin' })
      }
      else if (!localStorage.getItem('token')) {
        commit('setToken', null);
        router.push({ name: 'admin' })
      }
    }
  }
};
