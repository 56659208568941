import axios from 'axios';
import router from '../../router';
export default {
  state: {
    reports: [],
    status: {
      1: 'Оплата карткою',
      2: 'Оплата готівкою',
      3: 'Оплата обробляється',
      4: 'Оплата не підтверджена',
      5: 'Скасовано',
      6: 'Скасовано адміністратором',
    },
    metaPagination: null,
    linksPagination: null,
    tableData: null,
  },
  getters: {
    getMetaPagination: (state) => {
      return state.metaPagination;
    },
    getLinksPagination: (state) => {
      return state.linksPagination;
    },
    getStatusPay: (state) => {
      return state.status;
    },
    getReports: (state) => {
      return state.reports;
    },
    getReport: (state) => (id) => {
      return state.reports.find((report) => report.id === id);
    },
  },
  mutations: {
    setMetaPagination: (state, payload) => {
      return (state.metaPagination = payload);
    },
    setLinksPagination: (state, payload) => {
      return (state.linksPagination = payload);
    },

    setReports: (state, payload) => {
      return (state.reports = payload);
    },
    setTableData: (state, payload) => {
      return (state.tableData = payload);
    },
  },
  actions: {
    REPORTS_API({ commit }, page) {
      axios({
        method: 'GET',
        url: `users/me/bookings?page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
      }).then(function(response) {
        // console.log(response.data);

        commit('setReports', response.data.data);
        commit('setMetaPagination', response.data.meta);
        commit('setLinksPagination', response.data.links);

        commit('setTableData', response.data);

        window.scrollTo(0, 0);
      });
    },
    DELETE_BOOKING({ commit }, id) {
      axios({
        method: 'DELETE',
        url: `bookings/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
      })
        .then(function(response) {
          // console.log(response.data);
          sessionStorage.removeItem('cancel-booking');
          router.push({ name: 'report' });
        })
        .catch(function(err) {
          console.log(err);
        });
    },
  },
};
