import axios from "axios";
import router from "../../router";
export default {
  state: {
    userProfile: null,
    userBookings: null,
    userPhone: null,
    userName: null,
    userBalance: null,

    metaPaginationUserBookings: null,
    linksPaginationUserBookings: null,
    tableData: null,
  },
  getters: {
    getUserProfile: (state) => {
      return state.userProfile;
    },
    getUserBookings: (state) => {
      return state.userBookings;
    },
    getUserPhone: (state) => state.userPhone,
    getUserName: (state) => state.userName,
    getUserBalance: (state) => state.userBalance,

    getMetaPaginationUserBookings: (state) => {
      return state.metaPaginationUserBookings;
    },
    getLinksPaginationUserBookings: (state) => {
      return state.linksPaginationUserBookings;
    },
  },
  mutations: {
    setUserProfile: (state, payload) => {
      return (state.userProfile = payload);
    },
    setUserBookings: (state, payload) => {
      return (state.userBookings = payload);
    },

    setUserPhone: (state, payload) => {
      return (state.userPhone = payload);
    },
    setUserName: (state, payload) => {
      let { last_name, first_name } = payload;

      if (last_name && first_name) {
        return (state.userName = `(${last_name}, ${first_name})`);
      } else if (last_name && !first_name) {
        return (state.userName = `(${last_name})`);
      } else if (!last_name && first_name) {
        return (state.userName = `(${first_name})`);
      } else {
        return (state.userName = "");
      }
    },
    setUserBalance: (state, payload) => {
      return (state.userBalance = `${payload} грн`);
    },

    setMetaPaginationUserBookings: (state, payload) => {
      return (state.metaPaginationUserBookings = payload);
    },
    setLinksPaginationUserBookings: (state, payload) => {
      return (state.linksPaginationUserBookings = payload);
    },
    setTableData: (state, payload) => {
      return (state.tableData = payload);
    },
  },
  actions: {
    USER_GET_PROFILE({ commit }, userId) {
      commit("setUserPhone", "");
      commit("setUserName", { last_name: "", first_name: "" });
      commit("setUserBalance", "");
      if (!localStorage.getItem("token")) {
        router.push({ name: "booking" });
      } else {
        axios({
          method: "GET",
          url: `users/${userId}/profile`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(function (response) {
          // console.log(response.data);
          commit("setUserProfile", response.data);

          commit("setUserPhone", "+" + response.data.phone);

          let nameObj = {
            last_name: response.data.last_name ? response.data.last_name : "",
            first_name: response.data.first_name
              ? response.data.first_name
              : "",
          };
          commit("setUserName", nameObj);
          commit("setUserBalance", response.data.balance);

          window.scrollTo(0, 0);
        });
      }
    },

    USER_GET_BOOKINGS({ commit }, user) {
      commit("setUserBookings", null);
      commit("setMetaPaginationUserBookings", null);
      commit("setLinksPaginationUserBookings", null);
      if (!localStorage.getItem("token")) {
        router.push({ name: "booking" });
      } else {
        axios({
          method: "GET",
          url: `users/${user.userId}/bookings?page=${user.page}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(function (response) {
          // console.log(response.data);
          commit("setUserBookings", response.data);

          commit("setUserBookings", response.data.data);
          commit("setMetaPaginationUserBookings", response.data.meta);
          commit("setLinksPaginationUserBookings", response.data.links);

          commit("setTableData", response.data);

          window.scrollTo(0, 0);
        });
      }
    },
  },
};
