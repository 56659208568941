import axios from "axios";
import router from "../../router";

export default {
  state: {
    users: [],
    metaPaginationUsers: null,
    linksPaginationUsers: null,
    searchText: "",
    usersIsLoaded: false,
    tableData: null,
    userDiscount: 0,
  },
  getters: {
    getUsersIsLoaded: (state) => {
      return state.usersIsLoaded;
    },
    getUserItem: (state) => (id) => {
      return state.users.find((user) => user.id === id);
    },
    getUsers: (state) => {
      return state.users;
    },
    getMetaPaginationUsers: (state) => {
      return state.metaPaginationUsers;
    },
    getLinksPaginationUsers: (state) => {
      return state.linksPaginationUsers;
    },
    getSearchText: (state) => {
      return state.searchText;
    },
    getUserDiscount: (state) => {
      return state.userDiscount;
    },
    // getReport: state => id => {
    //   return state.reports.find(report => report.id === id);
    // },
  },
  mutations: {
    setUsersIsLoaded: (state, payload) => {
      return (state.usersIsLoaded = payload);
    },
    setUsers: (state, payload) => {
      return (state.users = payload);
    },
    setMetaPaginationUsers: (state, payload) => {
      return (state.metaPaginationUsers = payload);
    },
    setLinksPaginationUsers: (state, payload) => {
      return (state.linksPaginationUsers = payload);
    },
    setSearchText: (state, payload) => {
      return (state.searchText = payload);
    },
    setTableData: (state, payload) => {
      return (state.tableData = payload);
    },
    setUserDiscount: (state, payload) => {
      return (state.userDiscount = payload);
    },
  },
  actions: {
    USERS_GET({ commit, getters }, page = 1) {
      if (!localStorage.getItem("token")) {
        router.push({ name: "booking" });
      } else {
        axios({
          method: "GET",
          url: `users?page=${page}&s=${getters.getSearchText}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(function (response) {
          //  console.log(response.data);

          commit("setUsers", response.data.data);
          //  console.log(response.data.data);
          commit("setMetaPaginationUsers", response.data.meta);
          commit("setLinksPaginationUsers", response.data.links);

          commit("setTableData", response.data);
          window.scrollTo(0, 0);

          commit("setUsersIsLoaded", true);
        });
      }
    },

    USERS_SEARCH({ commit }, payload) {
      axios({
        method: "GET",
        url: `users?s=${payload}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(function (response) {
        // console.log(response.data);
        commit("setUsers", response.data.data);
        commit("setMetaPaginationUsers", response.data.meta);
        commit("setLinksPaginationUsers", response.data.links);
      });
    },

    ADD_PAYMENT_MANUALLY({ commit, state, getters }, comp) {
      axios({
        method: "POST",
        url: `users/${comp.dataPayment.userID}/payment`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          amount: comp.dataPayment.amount,
          comment: comp.dataPayment.comment,
        },
      })
        .then((response) => {
          // console.log(response);
          let newBalance = response.data.balance;
          let objUser = getters.getUserItem(response.data.id);
          let elemArray = state.users.indexOf(objUser);

          state.users[elemArray].balance = newBalance;
          // comp.$bvModal.hide('ModalAddPaymentManually');
          comp.closePaymentModal();
        })
        .catch((error) => {
          console.log("Error ADD_PAYMENT_MANUALLY", error);
        });
    },

    SET_DISCOUNT({ commit, state, getters }, comp) {
      axios({
        method: "POST",
        url: `users/${comp.currentUser.id}/discount`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          discount: comp.discountValue,
        },
      })
        .then((response) => {
          // console.log(response);
          const discountValue = response.data.discount;
          const objUser = getters.getUserItem(response.data.id);

          const elemArray = state.users.indexOf(objUser);
          state.users[elemArray].discount = discountValue;
          // comp.$bvModal.hide('ModalAddPaymentManually');
          comp.closeDiscountModal();
        })
        .catch((error) => {
          console.log("Error SET_DISCOUNT", error);
        });
    },

    SAVE_USER_NOTE({ commit }, data) {
      axios({
        method: "PUT",
        url: `/users/${data.user_id}/profile`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: data.note,
      })
        .then(function (response) {
          let $this = data.context;
          let id = response.data.id;
          let savedText = response.data.note;

          $this.$refs[`text-${id}`].innerText = savedText;
          $this.$refs[`text-${id}`].style.display = "block";
          $this.$refs[`textarea-${id}`].style.display = "none";
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
