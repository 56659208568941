<template>
  <div id="app">
    <b-container fluid>
      <header
        class="page__header"
        v-show="noAdmin"
      >
        <NavigationTop />
      </header>

      <div class="page__content">
        <router-view />
      </div>

      <footer
        class="page__footer"
        v-show="noAdmin"
      >
        <b-row>
          <b-col cols="8">
            <div>
              Ви можете ознайомитися з нашими <router-link :to="{name:'terms-conditions'}">Правилами та умовами</router-link>, а також <router-link :to="{name:'privacy-policy'}">Політикою конфіденційності</router-link>.
            </div>
            <div>

              {{footerCopy}}
            </div>

          </b-col>

          <b-col
            cols="4"
            class="text-right"
          >Created by: <img
              alt=""
              src="/img/logos/dmdlogo.png"
              style="margin-left:20px;height: 40px;"
            ></b-col>
        </b-row>

      </footer>
    </b-container>

  </div>
</template>

<script>
import NavigationTop from "./components/NavigationTop.vue";

import * as firebase from 'firebase/app';

export default {
  name: "app",
  components: {
    NavigationTop,
  },
  computed: {
    footerCopy () {
      const year = new Date().getFullYear()
      const str = process.env.VUE_APP_FOOTER_COPY;
      const func = new Function('year', 'return `' + str + '`;');
      return func(year);
    },
    noAdmin () {
      if (this.$route.name) {
        if (this.$route.name !== 'admin') {
          return true
        } else {
          return false
        }
      }

    }
    // getAllRooms () {
    //   return this.$store.getters.getAllRooms;
    // }
  },

  
  mounted () {
      //  $analytics.logEvent('start_game')
    // this.$analytics.logEvent('start_game', { level: '10', difficulty: 'expert' })

    // this.$analytics.logEvent('custom_event', { custom_field1: '10', custom_field2: 'asddasdasd' })

    // this.$analytics.logEvent('select_content', {
    //   content_type: 'image',
    //   content_id: 'P12453',
    //   items: [{ name: 'Kittens' }]
    // });

    //    this.$analytics.logEvent('select_content', {
    //   content_type: 'image2',
    //   content_id: 'P1233',
    //   items: [{ name: 'Kittens2' }]
    // });
  
    
//     const signIn = async () => {
//       const user = await firebase.auth().signInAnonymously();
//       const metthod = user.credential.signInMethod;
//       this.$analytics.logEvent('login', { metthod })

//     };
// signIn()

    if (this.$route.name === 'home') {
      this.$router.push({ path: '/booking' })
    }

    if (!sessionStorage.getItem('selectedDay') || this.$moment(sessionStorage.getItem('selectedDay')).isBefore(this.$moment(new Date()))) {
      sessionStorage.setItem('selectedDay', this.$store.state.dateToday)
      this.$store.commit('setUpDate', this.$store.state.dateToday)
      this.$store.commit('setBottomDate', this.$store.state.dateToday)
    } else {
      this.$store.commit('updateDate', sessionStorage.getItem('selectedDay'))
      this.$store.commit('setUpDate', sessionStorage.getItem('selectedDay'))
      this.$store.commit('setBottomDate', sessionStorage.getItem('selectedDay'))
    }


    this.$store.dispatch('GET_ROOMS_API');


    // this.$store.dispatch('GET_SLOTS_API', sessionStorage.getItem('selectedDay'));
    // this.$store.dispatch('REPORTS_API')
  }
};
</script>

<style lang="scss">
@import 'assets/scss/style';

body {
  font-family: 'IBM Plex Sans', sans-serif;
  color: black;
  font-size: 16px;
}

#app {
  & > .container-fluid {
    min-height: 100vh;
  }
  .container-fluid {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
  }
  & > *:focus {
    outline: none;
  }
}
.page__content {
  flex: 1;
}

header {
  height: 70px;
}

footer {
  margin-top: auto;
  height: 60px;
}
* {
  outline: none !important;
}
</style>
