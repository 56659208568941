import axios from 'axios';
export default {
  state: {
    rooms: null,
    currentRoom: null,
    roomsIsLoad: false,
    roomsNames: []
  },
  getters: {
    getRoomsNames: state => state.roomsNames,
    getRoomsIsLoad: state => state.roomsIsLoad,
    getAllRooms: state => state.rooms,
    getRoom: state => id => {
      return state.rooms.find(room => room.id === id);
    },
    getCurrentRoom: state => state.currentRoom
  },
  mutations: {
    setRoomsNames(state, payload) {
      state.roomsNames = payload;
    },
    setRoomsIsLoad(state, payload) {
      state.roomsIsLoad = payload;
    },
    setAllRooms(state, payload) {
      state.rooms = payload;
    },
    setCurrentRoom: (state, payload) => (state.currentRoom = payload)
  },
  actions: {
    GET_ROOMS_API({ commit }) {
      axios({
        method: 'GET',
        url: 'rooms'
      }).then(function(response) {
        let roomsArr = response.data

        //  console.log(roomsArr);
        
        commit('setAllRooms', roomsArr);
        setTimeout(() => {
          commit('setRoomsIsLoad', true);
        }, 1000);

       
        let names = [{ value: 0, text: 'Всі' }];
        response.data.forEach(({ id, name, color }, index) => {
          names.push({ value: id, text: name });
          document.documentElement.style.setProperty(`--room-color${index}`, color);
        });
        commit('setRoomsNames', names);
      });
    }
  }
};
