<template>
  <section class="cart-room-info">
    <b-carousel
      id="carousel-fade"
      fade
      :interval="3000"
    >
          <!-- v-for="(room_img, i) in room.images"
        :key="i" -->
      <b-carousel-slide
  
      
      >
        <template v-slot:img>
            <div
              :style="{ 'background-image': 'url(' +  room.images[0].url + ')' }"
              class="carousel__slide"
            >
            </div>
          </template>
      </b-carousel-slide>

    </b-carousel>

    <div
      v-show="showZoomCarousel"
      class="carousel-room--hide"
    >
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="showZoomCarousel = !showZoomCarousel"
      >×</button>
      <b-carousel
        id="carousel-hide"
        fade
        controls
        :interval="0"
        ref="carouselRoom"
      >

        <b-carousel-slide
          v-for="(room_img, i) in room.images"
          :key="i"
        >
          <template v-slot:img>
            <div
              :style="{ 'background-image': 'url(' + room_img.url + ')' }"
              class="carousel__slide"
            >
            </div>
          </template>
        </b-carousel-slide>

      </b-carousel>

    </div>

    <div
      class="type-room"
      style="padding: .25rem 0;"
    >
      <span
        class="color-line"
        :style="{ backgroundColor: room.color}"
      ></span>
      {{room.name}}
      <span
        class="button-info"
        v-b-toggle.collapse-1
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 496.158 496.158"
          style="enable-background:new 0 0 496.158 496.158;"
          xml:space="preserve"
        >
          <path
            style="fill:#25B7D3;"
            d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
	c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"
          />
          <g>
            <path
              style="fill:#FFFFFF;"
              d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
		c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
		c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
		c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
		c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
		c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
		c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
		c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"
            />
            <path
              style="fill:#FFFFFF;"
              d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
		c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
		c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"
            />
          </g>

        </svg>
      </span>
    </div>

    <b-collapse
      id="collapse-1"
      class="mt-2 collapse__room-info"
    >
      <p>
        <svg
          height="480pt"
          viewBox="0 -30 480 480"
          width="480pt"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m448 259.199219h-72v112h72zm-16 16v48h-40v-48zm-40 80v-16h40v16zm0 0" />
          <path d="m440 201.601562v-110.402343h-72v16h56v79.199219l-120-114.398438-176 168v179.199219h352v-179.199219zm-176 129.597657h16v-16h-16v-64h80v152h-80zm200 72h-104v-168h-112v168h-104v-156.800781l160-152 160 152zm0 0" />
          <path d="m32 387.199219h72v-144h-72zm16-16v-8h40v8zm40-112v88h-40v-88zm0 0" />
          <path d="m16 190.398438 184-168 66.398438 66.402343 11.203124-11.199219-77.601562-77.601562-200 184v235.199219h112v-16h-96zm0 0" />
          <path d="m272 51.199219h16v-32h-32v16h16zm0 0" />
          <path d="m90.285156 181.59375 111.972656-103.980469 10.890626 11.722657-111.972657 103.980468zm0 0" />
          <path d="m248 203.199219h112v16h-112zm0 0" /></svg>
        {{room.address}}
      </p>
      <p>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tag"
        >
          <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
          <line
            x1="7"
            x2="7"
            y1="7"
            y2="7"
          ></line>
        </svg>
      
        <template v-if="eventType && eventType.options.length>0">
          {{eventTypePrice(eventType.selected)}} грн/год
        </template>
        <template v-else>
              {{room.price}} грн/год
        </template>
        <!-- {{room.price}} грн/ч -->
      </p>
      <p>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-users"
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          <circle
            cx="9"
            cy="7"
            r="4"
          ></circle>
        </svg>
        {{room.capacity}}
      </p>
      <p class="text-description">
        <span v-html="room.description">
        </span>
        
      </p>
    </b-collapse>

    
  </section>
</template>

<script>
export default {
  props: {
    room: Object,
    eventType: Object,
    eventTypePrice: Function
  },
  name: "CartRoomInfo",
  data: () => ({
    showZoomCarousel: false,
  }),
  computed:{
    // getColorsRoom(){
    //   return this.$store.getters.getColorsRoom
    // }
  },
  watch: {
    showZoomCarousel: function (val) {
      if(this.showZoomCarousel === true){
        document.querySelector('body').classList.add('no-scroll')
      }else{
        document.querySelector('body').classList.remove('no-scroll')
      }
    }
  },
  mounted () {
    var slides = document.querySelectorAll('#carousel-fade .carousel-item');
    var $v = this;
    slides.forEach(slide => {
      slide.addEventListener('click', function () {
        $v.$refs.carouselRoom.setSlide(this.getAttribute('aria-posinset') - 1)
        $v.showZoomCarousel = true;
      }, false);
    });
  },
}

</script>

<style lang="scss">
.cart-room-info #carousel-fade img {
  height: 180px !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}
#carousel-fade{
  cursor: pointer;
}
.cart-room-info #carousel-fade   .carousel__slide{
     border-radius: 10px;
  }
.color-line {
  position: absolute;
  left: 0;
  width: 2px;
  display: inline-block;
  height: 100%;
}
.type-room {
  position: relative;
  padding-left: 10px !important;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.button-info svg {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
}
.collapse__room-info p {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.collapse__room-info svg {
  width: 15px;
  height: auto;
  margin-right: 5px;
}
.carousel-room--hide {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1111;
  display: flex;
  align-items: center;
}

.carousel-room--hide .close {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 111111;
}
.carousel-room--hide #carousel-hide {
  width: 100%;
}
.carousel__slide {
  height: 0;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.no-scroll{
  max-height: 100vh;
  overflow: hidden;
}

.carousel-control-next{
  opacity: 1 !important;
  .carousel-control-next-icon{
    opacity: 0.5;
  }
}
.carousel-control-prev{
  opacity: 1 !important;
  .carousel-control-prev-icon{
    opacity: 0.5;
  }
}
.carousel-control-next:hover{
  .carousel-control-next-icon{
    opacity: 0.9;
  }
}
.carousel-control-prev:hover{
  .carousel-control-prev-icon{
    opacity: 0.9;
  }
}

</style>

