import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import feather from 'vue-icon'
// import moment from 'moment'
import moment from 'moment-timezone'

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

Vue.config.productionTip = false
Vue.use(BootstrapVue, axios)
Vue.use(feather, 'v-icon')

// axios.defaults.baseURL = process.env.API_ENDPOINT;
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

// // const moment = require('moment')
// // require('moment/locale/ru')
moment.locale('ua')

Vue.use(require('vue-moment'), {
  moment
})


// console.log(Vue.moment().locale())

Vue.prototype.moment = moment;
// Vue.prototype.$http = axios;


import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCP0Gi4tpYOSlUmxt96DkmCJUltNzkPfQk",
  authDomain: "roomus-test-20d9b.firebaseapp.com",
  databaseURL: "https://roomus-test-20d9b.firebaseio.com",
  projectId: "roomus-test-20d9b",
  storageBucket: "roomus-test-20d9b.appspot.com",
  messagingSenderId: "322431107673",
  appId: "1:322431107673:web:1cd3c55b445081f77a6748",
  measurementId: "G-EQYL333MM6"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')