const getContentFromFile = async (file) => {
  try {
    const response = await fetch(file);
    const text = await response.text();
    return text;
  } catch (err) {
    console.log(err);
  }
};

export default getContentFromFile
