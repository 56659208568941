import axios from "axios";
import jwtDecode from "jwt-decode";
// import router from '../../router'
export default {
  state: {
    status: null,
    phone: null,
    code: null,
    token: null,
    isAdmin: null,
    clickedSignOut: false,
    reserveAfterLogin: false,
  },
  getters: {
    getClickedSignOut: state => state.clickedSignOut,
    getStatus: state => state.status,
    getPhone: state => state.phone,
    getCode: state => state.code,
    getToken: state => state.token,
    getIsAdmin: state => state.isAdmin,
    getReserveAfterLogin: state => state.reserveAfterLogin
  },
  mutations: {
    setClickedSignOut(state, payload) {
      state.clickedSignOut = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setPhone(state, payload) {
      state.phone = payload;
    },
    setCode(state, payload) {
      state.code = payload;
    },
    setToken(state, payload) {
      if (payload) {
        let adm = jwtDecode(payload);
        if (adm.is_admin) {
          state.isAdmin = adm.is_admin;
        } else {
          state.isAdmin = false;
        }
      } else {
        state.isAdmin = null;
      }
      state.token = payload;
    },
    setIsAdmin(state, payload) {
      state.isAdmin = payload;
    },
    setReserveAfterLogin(state, payload) {
      state.reserveAfterLogin = payload;
    },
  },
  actions: {
    SEND_CODE({ commit }, component) {
      axios({
        method: "POST",
        url: "login/sendCode",
        headers: { "content-type": "application/json" },
        data: {
          phone: component.phoneText,
        },
      })
        .then((response) => {
          // console.log('Response', response);

          commit("setPhone", component.phone);
          commit("setCode", "code");

          commit("setStatus", "");

          component.$bvModal.hide("modal-login-step-1");
          component.$bvModal.show("modal-login-step-2");
          component.startCountdown();
        })
        .catch((error) => {
          console.log("Error SEND_CODE", error);
          commit("setStatus", "error");
        });
    },

    CHECK_CODE({ commit, getters, dispatch }, component) {
      axios({
        method: "POST",
        url: "login/checkCode",
        headers: { "content-type": "application/json" },
        data: {
          phone: component.phoneText,
          code: component.password,
        },
      })
        .then((response) => {
          // console.log('Response',response);
          commit("setToken", response.data.token);
          localStorage.setItem("token", getters.getToken);
          component.$bvModal.hide("modal-login-step-2");

          if (!localStorage.getItem("first_login")) {
            component.$bvModal.show("hello-modal");
            localStorage.setItem("first_login", true);
          }

          component.phone = null;
          component.password = "";

          if (getters.getReserveAfterLogin) {
            dispatch("BOOKINGS_PAY", getters.getPayObject);
          }

        })
        .catch((error) => {
          console.log("Error SEND_CODE", error);
          commit("setStatus", "code-error");
        });
    },
  },
};
