import axios from 'axios';
// import router from '../../router'
import moment from 'moment-timezone';
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  state: {
    adminBookings: {},
    adminBookingsTransform: {},
    adminBookingsAllDate: {},
    adminBookingsCalendarValue: moment().format('YYYY-MM-DD'),
    adminSelectedTypeRoom: 0,
    admminBookingsCurrentRoom: null,

    adminBookingsUpDate: null,
    adminBookingsDownDate: null,

    adminBookingsMinDate: null,
    adminBookingsMaxDate: null,
  },
  getters: {
    getAdminBookingsMinDate: (state) => {
      return state.adminBookingsMinDate;
    },
    getAdminBookingsMaxDate: (state) => {
      return state.adminBookingsMaxDate;
    },
    getAdmminBookingsCurrentRoom: (state) => {
      return state.admminBookingsCurrentRoom;
    },
    getAdminBookings: (state) => {
      return state.adminBookings;
    },
    getAdminBookingsTransform: (state) => {
      return state.adminBookingsTransform;
    },
    getAdminBookingsAllDate: (state) => {
      return state.adminBookingsAllDate;
    },
    getAdminBookingsCalendarValue: (state) => {
      return state.adminBookingsCalendarValue;
    },
    getAdminSelectedTypeRoom: (state) => {
      return state.adminSelectedTypeRoom;
    },

    getAdminBookingsUpDate: (state) => {
      return state.adminBookingsUpDate;
    },
    getAdminBookingsDownDate: (state) => {
      return state.adminBookingsDownDate;
    },
  },
  mutations: {
    setClearAdminBookingsTransform: (state) => {
      state.adminBookingsTransform = {}
    },
    setAdminBookingsMinDate: (state, payload) => {
      state.adminBookingsMinDate = payload;
    },
    setAdminBookingsMaxDate: (state, payload) => {
      state.adminBookingsMaxDate = payload;
    },
    setAdmminBookingsCurrentRoom: (state, payload) => {
      sessionStorage.setItem('admin-bookings-active-slot', JSON.stringify(payload))
      return (state.admminBookingsCurrentRoom = payload);
    },
    setAdminBookingsUpDate: (state, payload) => {
      let date = moment(payload)
      .add(-1, 'days')
      .format('YYYY-MM-DD');
      return (state.adminBookingsUpDate = date);
    },
    setAdminBookingsDownDate: (state, payload) => {
      let date = moment(payload)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      return (state.adminBookingsDownDate = date);
    },
    setAdminSelectedTypeRoom: (state, payload) => {
      return (state.adminSelectedTypeRoom = payload);
    },
    setAdminBookings: (state, payload) => {

      return (state.adminBookings[payload.date] = payload.bookings);
    },
    setAdminBookingsAllDate: (state, payload) => {
      return (state.adminBookingsAllDate = payload);
    },

    setAdminBookingsTransformRemoveItem: (state, booking) => {
  
      let all_admin_bookings = state.adminBookings;

      for (let key in all_admin_bookings) {
        if (all_admin_bookings.hasOwnProperty(key)) {
          // console.log(all_admin_bookings[key]);
          let arr = all_admin_bookings[key]
          arr.forEach((el, i) => {
            if(el.id === booking.id) {
              arr.splice(i, 1);
            }
          })
        }
      }
      
      return state.adminBookings = all_admin_bookings

      // var transform_bookings = state.adminBookingsTransform

      // for (const key in transform_bookings) {
      //   if (transform_bookings.hasOwnProperty(key)) {
      //     for (const key2 in transform_bookings[key]) {
      //       if (transform_bookings[key].hasOwnProperty(key2)) {
      //         for (const key3 in transform_bookings[key][key2]) {
      //           if (transform_bookings[key][key2].hasOwnProperty(key3)) {
      //             console.log(transform_bookings[key][key2][key3]);
      //             let arr = transform_bookings[key][key2][key3]
      //             arr.forEach((el, i) => {
      //               console.log(i, el);
      //               if(el.id === booking.id) {
      //                 arr.splice(i, 1);
      //               }
      //             })
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      // return state.adminBookingsTransform = transform_bookings
    },

    setAdminBookingsTransform: (state, { date, bookings }) => {
  
      // Add elemets to Bookings
      bookings.forEach((el) => {
        let duration = Number(el.duration) / 60;
        el['startTime'] = moment(el.booking_date).format('HH:mm');
        el['endTime'] = moment(el.booking_date)
          .add(duration, 'hours')
          .format('HH:mm');
        el['durationHours'] = duration;
      });

      //Sort Room ID
      bookings.sort(function(a, b) {
        return a.room_id - b.room_id;
      });

      // Time Array
      let timeArr = [];
      for (let i = 0; i < 24; i += 0.5) {
        let h;
        let hStr = parseInt(i).toString();
        if (Number.isInteger(i)) {
          if (hStr.length < 2) {
            h = `0${hStr}:00`;
          } else {
            h = `${hStr}:00`;
          }
        } else {
          if (hStr.length < 2) {
            h = `0${hStr}:30`;
          } else {
            h = `${hStr}:30`;
          }
        }

        timeArr.push(h);
      }

      // Time sorting Bokings
      let sortTimeBookingsObj = {};
      timeArr.forEach((time) => {
        let filterTimeBookings = bookings.filter(function(el) {
          return time === el.startTime;
        });
        if (filterTimeBookings.length > 0) {
          sortTimeBookingsObj[time] = filterTimeBookings;
        }
      });

      //Filter for day parts
      let morning = {};
      let day = {};
      let evening = {};
      for (let key in sortTimeBookingsObj) {
        let time = key.split(':');
        let timeDay = parseInt(time[0]);
        if (Number(timeDay) < 13) {
          morning[key] = sortTimeBookingsObj[key];
        }
        if (Number(timeDay) >= 13 && Number(timeDay) < 18) {
          day[key] = sortTimeBookingsObj[key];
        }
        if (Number(timeDay) >= 18) {
          evening[key] = sortTimeBookingsObj[key];
        }
      }

      let dayParts = {
        morning,
        day,
        evening,
      };

      let key = moment(date).format('YYYYMMDD');

      let bookingsObj = state.adminBookingsTransform;
      bookingsObj[key] = dayParts;

      // state.adminBookingsAllDate
      // state.adminBookingsTransform = bookingsObj
      state.adminBookingsTransform = {
        ...state.adminBookingsTransform,
        ...bookingsObj,
      };
    },
    setAdminBookingsCalendarValue: (state, payload) => {
      return (state.adminBookingsCalendarValue = payload);
    },
  },
  actions: {
    ADMIN_BOOKINGS_API({ commit }, payload) {
      axios({
        method: 'GET',
        url: `bookings?date=${payload}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
      }).then(function(response) {
        // console.log(response.data);
        let { bookings, min_date, max_date } = response.data;
        let date = payload;

        commit('setAdminBookings', { date, bookings });
        commit('setAdminBookingsMinDate', min_date);
        commit('setAdminBookingsMaxDate', max_date);

        commit('setAdminBookingsTransform', { date, bookings });
      });
    },

    async ADMIN_BOOKING_INFO({ commit,getters }, payload){
      try {
        let get_booking_info = await axios({
          method: "GET",
          url: `bookings/${payload.id}`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json'
          },
        });
        
         let res = get_booking_info.data

        //  console.log(res);
         

         let booking_info = payload
        
         
          booking_info['user_note'] = res.user_note
          booking_info['status'] = res.status
          booking_info['amount'] = res.amount
          booking_info['created'] = res.created
          booking_info['comment'] = res.comment

      commit('setAdmminBookingsCurrentRoom', booking_info)
    
         
        //  console.log(user_note.user_note);
         
         
      } catch (e) {
       
        console.log('ERROR', e);
        // throw e
      }
    
   
    },


    async ADMIN_BOOKING_CANCEL({ commit }, id){

      try {
        let dele_booking = await axios({
          method: "DELETE",
          url: `bookings/${id}`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json'
          },
        });
        
         let res = dele_booking.data
         console.log(res);
         
      } catch (e) {
       
        console.log('ERROR', e);
        // throw e
      }
    
      
      // .then(function (response) {
      //   // console.log(response.data);
      //   sessionStorage.removeItem("cancel-booking")
      //   router.push({name: 'report'})
      // }).catch(function(err){
      //   console.log(err);
        
      // });
    }


  },
};
